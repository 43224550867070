@use './../../utils/variables' as v;

.home {
    display: flex;
    flex-direction: column;

    &__icon {
        font-size: 80px;
        color: var(--yoti-grey-black);

        @media only screen and (min-width: v.$mq-sm) {
            font-size: 100px;
        }
    }

    &__face-matching {
        display: flex;
        flex-direction: row;
    }

    &__cards-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div {
            margin: 10px;

            @media only screen and (min-width: v.$mq-sm) {
                margin: 40px;
            }
        }

        @media only screen and (min-width: v.$mq-sm) {
            flex-direction: row;
        }
    }

    &__tab {
        margin: auto;

        @media only screen and (min-width: v.$mq-sm) {
            max-width: 400px;
        }
    }
}
