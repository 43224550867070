@use './../../../utils/variables' as v;

.capture {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;

    &__selfie-capture-wrapper {
        margin: 5px 5px 0 5px;
    }

    &__title {
        font-size: v.$font-size-x-large;
        margin-bottom: 10%;
        text-align: center;

        &--secondary {
            color: v.$text-secondary-color;
        }
    }

    &__column {
        height: 100%;
        align-items: center;

        @media only screen and (min-width: v.$mq-lg) {
            height: inherit;
        }
    }

    &__predict-button {
        margin-top: 20px;
        text-align: center;

        @media only screen and (min-width: v.$mq-lg) {
            position: absolute;
            transform: translate(-50%, 0%);
            left: 50%;
            bottom: 5%;
        }
    }

    &__fixed-size-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &__panel {
            position: relative;
            overflow: hidden;
            box-shadow: v.$panel-box-shadow;
            border-radius: v.$panel-border-radius;
            background-color: var(--yoti-white);

            @include v.panel-dimensions(
                v.$mobile-height,
                v.$mobile-width,
                v.$tablet-height,
                v.$mobile-width,
                v.$laptop-height,
                v.$laptop-width
            );
        }

        &__back-button {
            color: v.$panel-secondary-color;
            font-size: 50px;
            position: absolute;
            left: 5%;
            bottom: 5%;
            cursor: pointer;

            @media only screen and (min-width: v.$mq-lg) {
                font-size: 70px;
                left: 2%;
                bottom: 5%;
            }
        }
    }

    &__back-button:hover {
        color: v.$panel-primary-color;
    }

    &__mobile-images-container {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}
