$text-primary-color: var(--yoti-grey-black);
$text-secondary-color: var(--yoti-grey-lightest);

$panel-primary-color: var(--yoti-grey-lightest);
$panel-secondary-color: var(--yoti-blue);

$mq-sm: 576px;
$mq-md: 768px;
$mq-lg: 992px;
$mq-x-lg: 1550px;

$panel-box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.25);
$panel-border-radius: 20px;
$panel-opacity: 0.95;

$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-x-large: 24px;
$font-size-2x-large: 36px;
$font-size-3x-large: 48px;

$panel-header-height: 50px;

// Panel dimensions:
$mobile-height: 116.8vw;
$mobile-width: 66vw;
$tablet-height: 101.33vw;
$tablet-width: 57vw;
$laptop-height: 28.125vw;
$laptop-width: 50vw;

@mixin panel-dimensions(
    $mobile-height,
    $mobile-width,
    $tablet-height,
    $tablet-width,
    $laptop-height,
    $laptop-width
) {
    height: $mobile-height;
    width: $mobile-width;
    // Tablet
    @media only screen and (min-width: $mq-md) {
        height: $tablet-height;
        width: $tablet-width;
    }
    // Mobile
    @media only screen and (min-width: $mq-lg) {
        height: $laptop-height;
        width: $laptop-width;
    }
}
