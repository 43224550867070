$button-height: 42px;
$button-width: 190px;

.button-container {
    position: relative;

    &__debug-button {
        position: absolute;
        top: 4px;
        left: 0;
        width: $button-width;
        height: $button-height;
        cursor: pointer;
        background: transparent;
        border: none;

        &:hover {
            background: rgb(255, 255, 255, 0.25);
        }
    }

    &__button {
        height: $button-height + 8px;
        width: $button-width;
    }
}
