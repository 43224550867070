@use './../../utils/variables' as v;

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--yoti-white);
    opacity: v.$panel-opacity;
    z-index: 50;
}

.dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    &__box {
        background-color: v.$panel-primary-color;
        box-shadow: v.$panel-box-shadow;
        padding: 20px;
        border-radius: v.$panel-border-radius;
        text-align: center;

        &__buttons-group {
            display: block;
            padding-top: 10px;
            @media only screen and (min-width: v.$mq-md) {
                padding-top: 20px;
            }

            &__button {
                display: inline-block;
                padding-top: 10px;
            }

            &__button:not(:last-child) {
                @media only screen and (min-width: v.$mq-sm) {
                    margin-right: 10px;
                }
            }
        }
    }
}
