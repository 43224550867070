@use './../../utils/variables' as v;

.alert {
    --yoti-alert-width: 200px;
    margin-top: 10px;

    &__header {
        font-weight: bold;
    }
}

.cookiesAlert {
    --yoti-alert-width: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @media only screen and (min-width: v.$mq-md) {
        --yoti-alert-width: 500px;
    }
}
