@use './../../utils/variables' as v;

.login {
    max-width: 400px;
    text-align: center;
    margin: auto;
    padding-top: 10%;

    &__panel {
        padding: 20px;
        background-color: v.$panel-primary-color;
        box-shadow: v.$panel-box-shadow;
        border-radius: v.$panel-border-radius;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
