@use './../../utils/variables' as v;

.panel {
    &__primary {
        top: 10%;
        bottom: 0;
        border-radius: 0px 0px v.$panel-border-radius v.$panel-border-radius;
        background-color: v.$panel-primary-color;
        color: v.$text-primary-color;
        padding: 40px 16px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (min-width: v.$mq-sm) {
            padding: 56px 30px 30px;
        }

        @media only screen and (min-width: v.$mq-md) {
            top: 0;
            right: 50%;
            padding: 12px 22px 12px 12px;
        }

        @media only screen and (min-width: v.$mq-lg) {
            top: 0;
            right: 50%;
            padding: 20px 5% 20px 20px;
        }
    }

    &__secondary {
        top: 0;
        bottom: 90%;
        border-radius: v.$panel-border-radius v.$panel-border-radius 0px 0px;
        background-color: v.$panel-secondary-color;
        color: v.$text-secondary-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;

        @media only screen and (min-width: v.$mq-md) {
            top: 0;
            left: 50%;
            bottom: 0;
            border-radius: 0px v.$panel-border-radius v.$panel-border-radius 0px;
            padding: 20px 20px 20px 5%;
            text-align: center;
        }
    }

    &__mobile-content {
        margin-bottom: 60px;
    }

    &__storage-message {
        position: absolute;
        bottom: 15px;
        padding-right: 20px;

        @media only screen and (min-width: v.$mq-md) {
            position: inherit;
            bottom: inherit;
            padding-right: inherit;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    &__title {
        color: var(--yoti-grey-black);
    }

    &__section-body {
        border-top: 2px solid;
        padding-top: 20px;
    }

    &__section-title {
        margin-bottom: 10px;
        font-size: v.$font-size-x-large;
    }

    &__radio-buttons {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        justify-content: space-between;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > button {
            margin: 1px;
        }

        @media only screen and (min-width: v.$mq-lg) {
            flex-direction: row;
        }
    }
}
