@use './../../utils/variables' as v;

.step-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;
    position: relative;

    &:first-child {
        padding-top: 0;
    }

    &__number {
        border-radius: 50%;
        min-width: 23px;
        width: 23px;
        height: 23px;
        margin-right: 16px;
        background: var(--yoti-white);
        border: solid 3px var(--yoti-grey);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: v.$font-size-medium;
        font-weight: bold;
        color: var(v.$text-primary-color);
        position: relative;
        z-index: 2;
    }

    &__line {
        position: absolute;
        top: 22px;
        bottom: -1px;
        left: 14.5px;
        transform: translate(-50%, 0%);
        width: 2px;
        background-color: var(--yoti-grey);
        flex-grow: 1;
        z-index: 1;
    }

    &__centered {
        align-self: center;
    }

    &:last-child &__line {
        display: none;
    }
}
