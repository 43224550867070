@use './../../utils/variables' as v;

.footer {
    text-align: center;
    width: 100%;
    height: 60px;
    margin-top: 10px;

    @media only screen and (min-width: v.$mq-md) {
        height: 90px;
        margin-top: 30px;
    }
}
