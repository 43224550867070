@use './../../../utils/variables' as v;

.button {
    width: 194px;
    height: 44px;
    cursor: pointer;
    border: none;
    background-color: v.$panel-primary-color;
    background-size: cover;
    padding: 0px;
    background-image: url('./btn_google_signin_dark_normal_web@2x.png');
    outline: '-webkit-focus-ring-color none';
    &:focus {
        background-image: url('./btn_google_signin_dark_focus_web@2x.png');
    }
    &:active {
        background-image: url('./btn_google_signin_dark_pressed_web@2x.png');
    }
}
