@use './../../utils/variables' as v;

.layout {
    padding: 0 1%;

    @media only screen and (min-width: v.$mq-md) {
        padding: 0 20%;
    }

    @media only screen and (min-width: v.$mq-lg) {
        padding: 0 25%;
    }
}
