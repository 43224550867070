@use './../../../utils/variables' as v;

$button-height: 3vw;
$button-margin: 2vw;

.root {
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-color: var(--yoti-grey-lightest);

    @include v.panel-dimensions(
        v.$mobile-height,
        v.$mobile-width,
        v.$tablet-height,
        v.$tablet-width,
        v.$laptop-height,
        v.$laptop-width
    );
}

.block {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
    height: v.$mobile-height - $button-height;
    @media only screen and (min-width: v.$mq-md) {
        margin-right: 15px;
        margin-left: 15px;
        height: v.$tablet-height - $button-height;
    }
    @media only screen and (min-width: v.$mq-lg) {
        flex-flow: row;
        height: v.$laptop-height - $button-height;
    }
}

.icon {
    flex: 1;
    margin-top: $button-margin;
    margin-bottom: -60px;
    transform: scale(0.6);
    @media only screen and (min-width: v.$mq-md) {
        margin-bottom: 0px;
        height: v.$tablet-height - $button-height;
    }
}

.error {
    display: flex;
    flex-flow: column;
    align-items: center;
    flex: 2;
    margin: 15% 10% 0 10%;
    @media screen and (min-width: v.$mq-lg) {
        margin: 0 $button-margin 0 0;
        display: block;
    }

    &_header {
        margin-bottom: 0;
        font-size: v.$font-size-x-large;
        @media screen and (min-width: v.$mq-sm) {
            font-size: v.$font-size-2x-large;
        }
        @media only screen and (min-width: v.$mq-lg) {
            margin-bottom: -15px;
        }
        @media only screen and (min-width: v.$mq-x-lg) {
            font-size: v.$font-size-3x-large;
        }
    }

    &_message {
        color: var(--yoti-grey-black);
        font-size: v.$font-size-medium;
        @media screen and (min-width: v.$mq-sm) {
            font-size: v.$font-size-large;
        }
        @media only screen and (min-width: v.$mq-x-lg) {
            font-size: v.$font-size-x-large;
        }
    }
}

.button {
    width: 150px;
    margin: -4 * $button-margin auto 4 * $button-margin auto;
    box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    @media only screen and (min-width: v.$mq-md) {
        margin: -2 * $button-margin auto 2 * $button-margin auto;
    }
    @media only screen and (min-width: v.$mq-lg) {
        margin: -$button-margin $button-margin 2 * $button-margin auto;
    }
    @media only screen and (min-width: v.$mq-x-lg) {
        margin: -$button-margin $button-margin $button-margin auto;
    }
}
