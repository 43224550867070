@use './../../utils/variables' as v;

.scan {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;

    &__wrapper {
        display: flex;
        justify-content: center;

        &__panel {
            position: sticky;
            overflow: hidden;
            box-shadow: v.$panel-box-shadow;
            border-radius: v.$panel-border-radius;
            background-color: var(--yoti-white);

            @include v.panel-dimensions(
                v.$mobile-height,
                v.$mobile-width,
                v.$tablet-height,
                v.$tablet-width,
                v.$laptop-height,
                v.$laptop-width
            );
        }
    }
}
