@use './../../../utils/variables' as v;

.result {
    display: flex;
    flex-flow: column;
    height: 100%;
    text-align: center;

    &__header {
        width: 100%;

        @media only screen and (min-width: v.$mq-lg) {
            margin: 0 auto;
        }
    }

    &__panel {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &__card-container {
            display: flex;
            flex-flow: column;
            align-items: center;
            padding: 5px;

            @media only screen and (min-width: v.$mq-lg) {
                flex-flow: row;
                align-items: flex-start;
            }

            &__card {
                margin: 10px;
                display: inline-block;

                @media only screen and (min-width: v.$mq-lg) {
                    margin: 15px;
                }

                &__image {
                    height: 100px;
                    width: 90px;
                    object-fit: cover;

                    @media only screen and (min-width: v.$mq-lg) {
                        width: 100%;
                        height: auto;
                        max-height: 250px;
                    }
                }
            }
        }

        &__response {
            width: 80%;
            min-height: 20%;
            box-shadow: v.$panel-box-shadow;
            border-radius: v.$panel-border-radius;
            background-color: var(--yoti-white);
            position: relative;
            padding-left: 5px;
            padding-right: 5px;

            @media only screen and (min-width: v.$mq-lg) {
                width: auto;
                min-height: 40%;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &__back-button {
            margin-top: 20px;

            @media only screen and (min-width: v.$mq-lg) {
                margin-top: 40px;
            }
        }
    }
}
