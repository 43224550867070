@use './../../utils/variables' as v;

.guidance {
    width: 100%;

    &__title {
        color: v.$text-primary-color;

        @media only screen and (min-width: v.$mq-lg) {
            color: v.$text-secondary-color;
        }
    }

    &__element {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0px;
        height: 50px;

        &__icon-wrapper {
            width: 70px;
            margin-right: 10px;
        }
    }
}
