@use './../../utils/variables' as v;

.consent {
    background-color: var(--yoti-grey-lightest);

    padding: 15px 25px 25px 25px;
    border-radius: v.$panel-border-radius;
    box-shadow: v.$panel-box-shadow;

    &__text {
        text-align: justify;
    }

    &__form {
        text-align: center;

        // TODO AITOOL-4263: use '--yds-button-width' when the application uses the
        // latest version for @yoti/react-components
        & button {
            @media only screen and (min-width: v.$mq-md) {
                width: auto;
                display: inline-flex;
            }

            width: 100%;
            display: block;
        }
    }
}
