@use '../../utils/variables' as v;

$circle-height-md: 80px;

.yoti-circle {
    position: absolute;
    top: v.$panel-header-height;
    right: 10%;
    width: 15vw;
    height: 15vw;
    transform: translate(0%, -50%);
    border-radius: 50%;
    border: 2.7vw solid var(--yoti-blue);
    box-sizing: border-box;
    background-color: var(--yoti-grey-lightest);

    @media only screen and (min-width: v.$mq-sm) {
        width: 12vw;
        height: 12vw;
        border: 2.16vw solid var(--yoti-blue);
        transform: translate(0%, -50%);
    }

    @media only screen and (min-width: v.$mq-md) {
        width: 9vw;
        height: 9vw;
        border: 1.4vw solid var(--yoti-blue);
        transform: translate(-50%, -50%);
        top: $circle-height-md;
        right: 2%;
    }

    @media only screen and (min-width: v.$mq-lg) {
        width: 5vw;
        height: 5vw;
        top: 50%;
        left: 50%;
        border: 0.9vw solid var(--yoti-blue);
        transform: translate(-50%, -50%);
    }
}
