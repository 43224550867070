@use './../../utils/variables' as v;

.response {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: left;

    &__container {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }

    &__element {
        display: flex;
        align-items: center;
        padding-top: 3px;
        padding-bottom: 3px;

        &__icon-error {
            color: var(--yoti-danger-red);
            margin-right: 0.25vw;
            font-size: 30px;
        }
    }
}
