@use './../../../utils/variables' as v;

.card {
    &__inner-container {
        display: flex;
        flex-flow: column;
        border-radius: v.$panel-border-radius;
        box-shadow: v.$panel-box-shadow;

        &--right {
            flex-flow: row;
        }

        &__primary-content {
            display: flex;
            background-color: v.$panel-primary-color;
            overflow: hidden;
            border-radius: v.$panel-border-radius v.$panel-border-radius 0px 0px;

            &--right {
                height: 100%;
                border-radius: v.$panel-border-radius 0px 0px v.$panel-border-radius;
            }

            &--no-secondary-content {
                border-radius: v.$panel-border-radius;
            }
        }

        &__secondary-content {
            background-color: v.$panel-primary-color;
        }

        &__handler {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: v.$panel-secondary-color;
            color: v.$text-secondary-color;
            border-radius: 0px 0px v.$panel-border-radius v.$panel-border-radius;

            &--right {
                border-radius: 0px v.$panel-border-radius v.$panel-border-radius 0px;
            }

            &:hover {
                background-color: var(--yoti-blue-tone);
            }

            &:focus {
                background-color: var(--yoti-blue-tone);
            }

            &:active {
                background-color: var(--yoti-blue-shade);
            }
        }
    }
}
