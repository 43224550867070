@use './../../../utils/variables' as v;

.result {
    display: flex;
    flex-flow: column;
    height: 100%;
    text-align: center;

    &__header {
        width: 100%;

        @media only screen and (min-width: v.$mq-lg) {
            width: 50%;
            margin: 0 auto;
        }
    }

    &__back-button {
        margin-top: 20px;

        @media only screen and (min-width: v.$mq-lg) {
            margin-top: 40px;
        }
    }

    &__panel {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: auto;

        @media only screen and (min-width: v.$mq-lg) {
            width: v.$laptop-width;
        }

        &__image {
            width: 70%;
            box-shadow: v.$panel-box-shadow;
            border-radius: v.$panel-border-radius;
            margin-bottom: 10px;
            @media only screen and (min-width: v.$mq-lg) {
                width: 50%;
            }
        }

        &__response {
            width: 100%;
            box-shadow: v.$panel-box-shadow;
            border-radius: v.$panel-border-radius;
            background-color: var(--yoti-white);
            position: relative;

            @media only screen and (min-width: v.$mq-lg) {
                width: 50%;
            }
        }
    }
}
