@use './../../utils/variables' as v;

.drag-and-drop {
    text-align: center;
    border: 4px dashed;
    cursor: pointer;
    border-radius: v.$panel-border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;

    &__container {
        &--primary {
            color: var(--yoti-grey);
        }
        &--secondary {
            color: var(--yoti-white);
        }
        &__border {
            color: v.$panel-secondary-color;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        &__drop-message {
            font-size: v.$font-size-medium;
            word-wrap: break-word;
            margin-left: 5px;
            margin-right: 5px;
        }

        &__camera-icon {
            font-size: 50px;

            @media only screen and (min-width: v.$mq-lg) {
                font-size: 70px;
            }
        }
    }

    &__help-icon-container {
        position: absolute;
        right: 3%;
        top: 5%;
        font-size: 5px;
        outline: none;
    }

    &__file-remove {
        position: absolute;
        display: flex;
        right: 3%;
        top: 5%;
        background-color: v.$panel-primary-color;
        border-radius: 50px;
        font-size: 5px;
        color: v.$text-primary-color;
    }

    &__file-remove:hover {
        color: var(--yoti-danger-red-tone);
    }

    &__image-loaded {
        width: 100%;
        height: 100%;
        border-radius: v.$panel-border-radius;
        object-fit: cover;
    }

    &__tooltip {
        border-radius: v.$panel-border-radius;
        background-color: v.$panel-primary-color;
        color: v.$text-primary-color;
        box-shadow: v.$panel-box-shadow;
        position: fixed;
        padding: 10px !important;
        top: 30%;
        left: 50%;
        transform: translate(-50%, 0%);
        font-size: 16px;
        z-index: 3;
        margin-bottom: 20px;
        width: 250px;

        @media only screen and (min-width: v.$mq-lg) {
            width: auto;
        }

        &__list {
            padding: 5px 5px 0px 5px;
            margin-left: 15px;
            text-align: left;
            margin-bottom: 0;

            & > li {
                margin-bottom: 2px;
            }
        }
    }
}
