@use './../../utils/variables' as v;

.card {
    border-radius: v.$panel-border-radius;
    min-width: 180px;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
    outline: none;

    &:hover {
        box-shadow: v.$panel-box-shadow;
    }

    &__header {
        background-color: var(--yoti-blue);
        border-top-left-radius: v.$panel-border-radius;
        border-top-right-radius: v.$panel-border-radius;
        height: 50px;
        text-align: center;
    }

    &__title {
        color: v.$text-secondary-color;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 50px;
        white-space: nowrap;
    }

    &__content {
        background-color: var(--yoti-grey-lightest);
        border-bottom-left-radius: v.$panel-border-radius;
        border-bottom-right-radius: v.$panel-border-radius;
        aspect-ratio: 7/4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (min-width: v.$mq-sm) {
        min-width: 230px;
    }

    @media only screen and (min-width: v.$mq-md) {
        min-width: 300px;
    }

    @media only screen and (min-width: v.$mq-lg) {
        min-width: 350px;
    }
}
