@use './../../../utils/variables' as v;

.response {
    $margin: 0.5vw;

    &__icon {
        color: v.$text-primary-color;
        font-size: 24px;
        margin-right: $margin;
        min-width: 24px;
    }

    &__text {
        color: v.$text-primary-color;
        margin-right: $margin;
        &--passed {
            color: var(--yoti-success-green);
        }
        &--undetermined {
            color: var(--yoti-warning-orange);
        }
        &--failed {
            color: var(--yoti-danger-red);
        }
        &--secondary {
            color: var(--yoti-grey-dark);
        }
        &--bold {
            font-family: var(--yoti-font-bold);
        }
    }

    &__age-accuracy-error {
        max-width: 250px;
    }
}
