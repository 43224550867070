@use './../../utils/variables' as v;

.face-matching-panel {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;

    &__wrapper {
        display: flex;
        justify-content: center;

        &__panel {
            position: relative;
            overflow: hidden;
            box-shadow: v.$panel-box-shadow;
            border-radius: v.$panel-border-radius;
            background-color: var(--yoti-white);
            width: auto;

            @media only screen and (min-width: v.$mq-lg) {
                width: 100%;
            }
        }
    }

    &__fixed-size-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &__panel {
            position: relative;
            overflow: hidden;
            box-shadow: v.$panel-box-shadow;
            border-radius: v.$panel-border-radius;
            background-color: var(--yoti-white);

            @include v.panel-dimensions(
                v.$mobile-height,
                v.$mobile-width,
                v.$tablet-height,
                v.$mobile-width,
                v.$laptop-height,
                v.$laptop-width
            );
        }

        &__back-button {
            color: v.$panel-secondary-color;
            font-size: 50px;
            position: absolute;
            left: 5%;
            bottom: 5%;
            cursor: pointer;

            @media only screen and (min-width: v.$mq-lg) {
                font-size: 70px;
                left: 2%;
                bottom: 5%;
            }
        }
    }

    &__back-button:hover {
        color: v.$panel-primary-color;
    }
}
