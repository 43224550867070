@use './../../utils/variables' as v;

.header {
    display: inline-flex;
    width: 100%;

    &__icon {
        font-size: 30px;
        color: var(--yoti-blue);
        cursor: pointer;
        padding: 15px;

        @media only screen and (min-width: v.$mq-md) {
            font-size: 50px;
            padding: 30px;
        }

        &--main-page {
            margin-right: auto;
        }
        &--log-out {
            margin-left: auto;
        }
    }

    &__icon:hover {
        color: v.$panel-primary-color;
    }
}
