@use './../../../utils/variables' as v;

$box-height: 34px;
$space: calc($box-height/3);
$double-space: $box-height * calc(5 / 3);

.scan_tab {
    &__user_requests {
        position: absolute;
        font-size: v.$font-size-medium;
        background-color: var(--yoti-white);
        z-index: 100;
        border: 3px solid var(--yoti-blue);
        border-radius: 10px;
        opacity: 0.5;
        white-space: nowrap;
        padding: 5px 10px;
        left: 50%;
        bottom: $space;
        height: $box-height;
        box-sizing: border-box;

        &--total {
            transform: translate(-50%, 0%);
            @media only screen and (min-width: v.$mq-lg) {
                right: 1%;
                transform: translate(0%, 0%);
                left: auto;
            }
        }

        &--daily {
            transform: translate(-50%, 0%);
            bottom: $double-space;
            @media only screen and (min-width: v.$mq-lg) {
                left: 1%;
                transform: translate(0%, 0%);
                bottom: $space;
            }
        }
    }
}
