@use './utils/variables' as v;

// Import here all the needed .css for the used @yoti/react-components.
@import '~@yoti/react-components/globals/globals.css';
@import '~@yoti/react-components/button/button.css';
@import '~@yoti/react-components/tabs/tabs.css';
@import '~@yoti/react-components/theme/theme.css';
@import '@yoti/react-components/radio-buttons/radio-buttons.css';
@import '@yoti/react-components/icon/icon.css';
@import '@yoti/react-components/loading/loading.css';
@import '@yoti/react-components/alert/alert.css';
@import '@yoti/react-components/checkbox/checkbox.css';

html {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: var(--yoti-font-regular);
    font-size: v.$font-size-medium;
    height: 100%;
}

body {
    margin: 0px;
    height: 100%;
    min-height: 100%;
}

body > div#root {
    height: 100%;
}
