@use './../../utils/variables' as v;

.selfie-capture {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media only screen and (min-width: v.$mq-md) {
        width: 100%;
        height: 100%;
    }

    &__drag-drop {
        height: 30vw;
        width: 30vw;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 10px;

        @media only screen and (min-width: v.$mq-md) {
            height: 12vw;
            width: 100%;
        }
    }

    &__button-wrap {
        margin-top: 5px;
        width: 100%;
        max-width: 150px;
    }

    &__file-input {
        display: none;
    }
}
