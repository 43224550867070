@use '../../utils/variables' as v;

$circle-padding: 8vw;
$circle-padding-md: 3vw;

.panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: v.$panel-primary-color;

    @media only screen and (min-width: v.$mq-md) {
        flex-direction: row;
        background: linear-gradient(
            90deg,
            v.$panel-primary-color 50%,
            v.$panel-secondary-color 50%
        );
    }

    &__header {
        min-height: v.$panel-header-height;
        padding: 10px;
        box-sizing: border-box;
        background-color: v.$panel-secondary-color;
        color: v.$text-secondary-color;
        display: flex;
        justify-content: flex-end;

        @media only screen and (min-width: v.$mq-md) {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__column {
        $standard-padding: 10px;

        color: v.$text-primary-color;
        box-sizing: border-box;
        margin-top: $circle-padding;
        margin-bottom: 20px;
        padding-left: $standard-padding;
        padding-right: $standard-padding;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        position: relative;

        @media only screen and (min-width: v.$mq-md) {
            height: auto;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 20px;
            padding-right: $circle-padding-md;
        }

        &--blue {
            color: v.$text-primary-color;
            background-color: v.$panel-secondary-color;
            padding-left: $circle-padding-md;
            padding-right: $standard-padding;
        }
    }

    &__mobile-right-container {
        margin-top: auto;
        padding: 10px 20px 10px 20px;
    }
}
