@use './../../utils/variables' as v;

.not-found {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &__icon {
        color: var(--yoti-grey);
        font-size: 125px;
    }

    &__text {
        margin-top: 10px;
        font-size: v.$font-size-x-large;
        color: v.$text-primary-color;
    }
}
