@use './../../../utils/variables' as v;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.img {
    width: 100px;
    height: 100px;
}
