@use '../../utils/variables' as v;

$circle-padding: 8vw;
$circle-padding-md: 3vw;
$header-height-md: 80px;

.panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: v.$panel-primary-color;

    @media only screen and (min-width: v.$mq-lg) {
        flex-direction: row;
    }

    &__header {
        min-height: v.$panel-header-height;
        padding: 10px 10px 0 10px;
        box-sizing: border-box;
        background-color: v.$panel-secondary-color;
        color: v.$text-secondary-color;
        display: flex;
        justify-content: flex-end;

        @media only screen and (min-width: v.$mq-md) {
            min-height: $header-height-md;
        }

        @media only screen and (min-width: v.$mq-lg) {
            min-height: v.$panel-header-height - 30px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__primary {
        color: v.$text-primary-color;
        box-sizing: border-box;
        overflow-y: auto;
        height: auto;
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 20px $circle-padding-md 20px 20px;
    }

    &__mobile-body-wrapper {
        color: v.$text-primary-color;
        margin-top: $circle-padding-md;
        padding: 0px 20px 20px;
        overflow-y: auto;
        justify-content: space-between;
        height: 100%;
    }

    &__secondary {
        width: 50%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        background-color: v.$panel-secondary-color;
        padding: 20px 20px 20px $circle-padding-md;
        box-sizing: border-box;

        &__content {
            color: v.$text-secondary-color;
        }
    }

    &__right-footer,
    &__right-header {
        font-size: v.$font-size-small;
    }

    &__right-footer {
        position: absolute;
        bottom: 0;
        min-height: v.$panel-header-height;
        width: 100%;

        @media only screen and (min-width: v.$mq-lg) {
            margin-top: auto;
        }

        &__container {
            padding: 10px 40px 10px 20px;

            @media only screen and (min-width: v.$mq-lg) {
                padding: 10px 20px 10px 40px;
            }
        }
    }
}
